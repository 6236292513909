import { createApp } from 'vue'
import router from "./router";
import App from './App'
import waterfall from 'vue-waterfall2'
import './assets/style.css'

// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)
app.use(waterfall)
app.mount('#app')