<template>
    <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div class="sm:mx-auto sm:w-full sm:max-w-sm">
            <img class="mx-auto w-auto" src="../../assets/bricksee-logo.png" alt="bricksee logo" style="height: 175px;" />
            <h2 class="mt-5 text-center text-2xl font-bold leading-9 tracking-tight ">Sign in
            </h2>
        </div>

        <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            <div v-if="error" class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-500"
                role="alert">
                <span class="font-medium">Error:</span> {{ error }}
            </div>
            <form class="space-y-6" action="#" method="POST" @submit.prevent="login">
                <div>
                    <label for="email" class="block text-sm font-medium leading-6 ">Email address</label>
                    <div class="mt-2">
                        <input id="email" name="email" type="email" v-model="email" autocomplete="email" required=""
                            class="block w-full bg-inherit rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <div class="flex items-center justify-between">
                        <label for="password" class="block text-sm font-medium leading-6 ">Password</label>
                    </div>
                    <div class="mt-2">
                        <input id="password" name="password" v-model="password" type="password"
                            autocomplete="current-password" required=""
                            class="block w-full bg-inherit rounded-md border-0 py-1.5  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                    </div>
                </div>

                <div>
                    <button type="submit"
                        class="flex w-full justify-center rounded-md bg-red-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Sign
                        in</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
    name: "LoginView",
    data() {
        return {
            email: '',
            password: '',
            error: null, // Add error property
        };
    },
    methods: {
        async login() {
            // Authenticate the user
            try {
                const auth = getAuth();
                signInWithEmailAndPassword(auth, this.email, this.password)
                    .then((userCredential) => {
                        // Signed in
                        if (userCredential.user) {
                            const redirectUrl = this.$route.query.redirect || '/dashboard'; // Default redirect URL is '/dashboard'
                            this.$router.push(redirectUrl);
                        }
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        console.log(errorCode, errorMessage);
                    });
            } catch (error) {
                this.error = error.message; // Set the error message
            }
        },
    },
}
</script>