<template>
  <h1>User Details</h1>
</template>

<script>
export default {
  name: "UserDetail"
}
</script>

<style scoped>

</style>