import { initializeApp } from 'firebase/app';
import { getFirestore, serverTimestamp } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, browserLocalPersistence, setPersistence } from 'firebase/auth';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCTBx-jWWywE3dX-o_HtrFDxJDLBQDxO1A",
    authDomain: "lego-app-8cb5d.firebaseapp.com",
    databaseURL: "https://lego-app-8cb5d.firebaseio.com",
    projectId: "lego-app-8cb5d",
    storageBucket: "lego-app-8cb5d.appspot.com",
    messagingSenderId: "223323476041",
    appId: "1:223323476041:web:abad99046d1d1138e3ef98",
    measurementId: "G-SN7S55YKPT"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const timestamp = serverTimestamp;
const auth = getAuth(app);
setPersistence(auth, browserLocalPersistence);

export { db as firestore, storage, timestamp, auth };