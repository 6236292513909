<template>
  <div>
    <div class="pb-5 border-b border-gray-200">
      <h3 class="text-lg leading-6 font-medium">Most recent detections</h3>
    </div>
    <div class="grid grid-cols-1 md:grid-cols-5 gap-4 pt-5">
      <div v-for="image in images" :key="image.id">
        <div class="overflow-hidden">

          <router-link :to="{ name: 'DetectionDetail', params: { id: image.id, url: image.url } }">
            <div class="card card-image-cover">
              <img :src="image.url" alt="firebase-image" />
              <div class="card-body p-4">
                <div class="card-footer">
                  <button class="btn-primary btn btn-block">View Details</button>
                </div>
                <div class="flex justify-between">
                  <span class="text-gray-400 text-sm">{{ formatTimestamp(image.date) }}</span>
                  <span
                    class="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10"
                    v-if="image.flagged">Flagged</span>
                </div>
              </div>
            </div>
          </router-link>

        </div>
      </div>
    </div>
    <div class="text-center py-5" v-if="isLoading">
      <svg class="spinner-ring spinner-primary spinner-xl" viewBox="25 25 50 50" stroke-width="5">
        <circle cx="50" cy="50" r="20" />
      </svg>
    </div>
  </div>
</template>

<script>
import { firestore, storage } from '../../firebase';
import { collection, getDocs, query, limit, startAfter, orderBy } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import { formatDistanceToNow } from 'date-fns';

const PAGE_SIZE = 20;

export default {
  name: "DetectionsOverview",
  data() {
    return {
      images: [],
      lastVisible: null,
      firstVisible: null,
      hasPrevious: false,
      isLoading: false
    };
  },
  mounted: function () {
    this.getNext()
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount: function () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      let bottomOfWindow = Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop
      ) + window.innerHeight === document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        this.getNext();
      }
    },
    async getImageUrl(imageId) {
      try {
        const imageRef = ref(storage, 'detections/images/' + imageId + ".jpg");
        const imageUrl = await getDownloadURL(imageRef);
        return imageUrl;
      } catch (error) {
        console.error("Error getting image URL:", error);
        return "https://assets-prd.ignimgs.com/2022/08/24/lego-button-2-1661351794140.jpg"; // provide a fallback image URL here
      }
    },
    async getImages(startAfterDoc = null) {
      console.log("attempting to get images", startAfterDoc)
      let imagesQuery = query(collection(firestore, "detections"), orderBy("timestamp.seconds", "desc"), limit(PAGE_SIZE));

      if (startAfterDoc) {
        imagesQuery = query(imagesQuery, startAfter(startAfterDoc));
      }

      const querySnapshot = await getDocs(imagesQuery);

      const imagePromises = querySnapshot.docs.map(async doc => {
        const doct = doc.data()
        const detectionData = doct.detection;
        if (detectionData && detectionData.id) {
          var imageUrl = "";
          if (doct.remoteImageUrl) {
            imageUrl = doct.remoteImageUrl;
          } else {
            imageUrl = await this.getImageUrl(detectionData.id);
          }
          return { url: imageUrl, date: doct.timestamp, id: doc.id, flagged: doct.flagged };
        }
        return null;
      });

      const resolvedImages = await Promise.all(imagePromises);
      const images = resolvedImages.filter(img => img !== null);

      if (!querySnapshot.empty) {
        this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        this.firstVisible = querySnapshot.docs[0];
        this.hasPrevious = !!startAfterDoc;
      }

      return images;
    },
    getNext() {
      if (this.isLoading) return; // Prevent multiple simultaneous fetches
      this.isLoading = true;

      this.getImages(this.lastVisible).then(images => {
        this.images = this.images.concat(images);
        this.isLoading = false;
      });
    },
    formatTimestamp(timestamp) {
      console.log(timestamp)
      // Convert the timestamp to milliseconds and add the start of the Third Millennium
      const date = new Date((timestamp.seconds * 1000));
      // Format the date as a "time ago" string
      const timeAgo = formatDistanceToNow(date, { addSuffix: true });
      return timeAgo;
    }
  }
}
</script>

<style scoped></style>