// router.js

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from './views/home.vue'
import UsersOverview from './views/users/home'
import UserDetail from './views/users/details'
import DetectionsOverview from './views/detections/home.vue'
import DetectionDetail from './views/detections/details'
import LoginView from './views/auth/login'
import { auth } from './firebase.js';
import { onAuthStateChanged } from 'firebase/auth'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: { requiresAuth: true }
    },
    {
        path: '/users',
        name: 'Users',
        component: UsersOverview,
        meta: { requiresAuth: true }
    },
    {
        path: '/users/:id',
        name: 'UserDetail',
        component: UserDetail,
        meta: { requiresAuth: true }
    },
    {
        path: '/detections',
        name: 'Detections',
        component: DetectionsOverview,
        meta: { requiresAuth: true }
    },
    {
        path: '/detections/:id/:url',
        name: 'DetectionDetail',
        component: DetectionDetail,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: { requiresAuth: false }
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        onAuthStateChanged(auth, user => {
            console.log(user);
            if (!user) {
                next({
                    path: '/login',
                    query: { redirect: to.fullPath }
                });
            } else {
                next();
            }
        });
    } else {
        next();
    }
});

export default router