<template>
  <div class="container mx-auto">
    <div class="pb-5 border-b">
      <nav class="flex justify-between" aria-label="Breadcrumb">
        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li class="inline-flex items-center">
            <a href="#"
              class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
              <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                viewBox="0 0 20 20">
                <path
                  d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </a>
          </li>
          <li>
            <div class="flex items-center">
              <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 9 4-4-4-4" />
              </svg>
              <router-link to="/detections"
                class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Detections</router-link>
            </div>
          </li>
          <li aria-current="page">
            <div class="flex items-center">
              <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 9 4-4-4-4" />
              </svg>
              <a href="#" v-if="activeTitle != ''"
                class="ms-1 text-sm font-medium text-gray-900 md:ms-2 dark:text-gray-200" @click="viewDetails('')">
                {{ documentId }}
              </a>
              <span v-if="activeTitle == ''" class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">
                {{ documentId }}
              </span>
            </div>
          </li>
          <li aria-current="page" v-if="activeTitle != ''">
            <div class="flex items-center">
              <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 9 4-4-4-4" />
              </svg>
              <span class="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">{{ activeTitle }}</span>
            </div>
          </li>
        </ol>
        <button class="btn btn-blue is-fullwidth" @click="exportData">Export</button>
      </nav>
    </div>
    <div class="grid grid-cols-2 gap-4 pt-5 flex flex-wrap">
      <div>
        <div class="sticky top-4">
          <img :src=imageUrl alt="" class="rounded-lg " @load="getImageSize" ref="imageRef">
          <div v-for="box in boundingBoxes" :key="box.id" class="bounding-box" :style="getBoxStyle(box)"
            :class="{ 'highlight': activeItem === box.id, 'faded': activeItem !== null && activeItem !== box.id }"></div>
        </div>

      </div>
      <div>
        <div class="card max-w-none">
          <div class="card-body">
            <h2 class="card-header">Information</h2>
            <p class="text-gray-500">{{ boundingBoxGroups.items.length || -1 }} parts detected</p>
            <div class="divider"></div>
            <div class="text-content2">
              <div class="text-center py-5" v-if="isLoading">
                <svg class="spinner-ring spinner-primary spinner-xl" viewBox="25 25 50 50" stroke-width="5">
                  <circle cx="50" cy="50" r="20" />
                </svg>
              </div>
              <div>
                <table class="table table-zebra is-fullwidth" v-if="!activeGroup" @mouseleave="activeItem = null">
                  <tbody>
                    <tr v-for="(group, index) in boundingBoxGroups.items" :key="index"
                      @mouseover="activeItem = group.rawData.id">
                      <td>{{ group.title }}</td>
                      <td>
                        <button class="btn btn-solid-secondary btn-sm" @click="viewDetails(group)">View</button>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div v-if="activeGroup">
                  <table class="table is-fullwidth">
                    <tbody>
                      <tr v-for="(item, index) in activeGroup.data" :key="index">
                        <td>{{ item.title }}</td>
                        <td>{{ item.data }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card max-w-none mt-4">
          <div class="card-body">
            <div v-if="!activeGroup">
              <div v-for="(group, index) in deviceInfoGroups" :key="index" class="min-h-0 w-full">
                <h2 class="card-header">{{ group.title }}</h2>
                <div class="divider"></div>
                <table class="table-compact table">
                  <tbody>
                    <tr v-for="(item, index) in group.items" :key="index">
                      <td>{{ item.title }}</td>
                      <td>{{ item.data }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="activeGroup">
              <h2 class="card-header">Predictions</h2>
              <table class="table table-auto">
                <tbody>
                  <tr class="break-words" v-for="(item, index) in activeGroup.observations" :key="index">
                    <td class="break-words">{{ item.identifier }}</td>
                    <td>{{ item.confidence.toFixed(2) }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card max-w-none mt-4" v-if="activeGroup">
          <div class="card-body">
            <h2 class="card-header">Colours</h2>
            <table class="table is-fullwidth">
              <tbody>
                <tr v-for="(item, index) in activeGroup.colours" :key="index"
                  :style="{ 'background-color': `#${item.rgb}` }">
                  <td>{{ item.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { firestore } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';

export default {
  name: "DetectionDetail",
  computed: {
    documentId() {
      // We will see what `params` is shortly
      return this.$route.params.id
    },
    imageUrl() {
      // We will see what `params` is shortly
      return this.$route.params.url
    },
    boundingBoxGroups() {
      if (!this.document.detection) {
        return {
          title: 'Bounding boxes',
          items: [],
        }
      }
      return {
        title: 'Bounding boxes',
        items: this.document.detection.parts.map((box) => ({
          title: box.observations[0].identifier,
          rawData: box,
          data: [
            { title: 'ID', data: box.id },
            { title: 'Bounding Box X', data: box.boundingBoxX },
            { title: 'Bounding Box Y', data: box.boundingBoxY },
            { title: 'Bounding Box Width', data: box.boundingBoxWidth },
            { title: 'Bounding Box Height', data: box.boundingBoxHeight },
          ],
          observations: box.observations,
          colours: box.colours
        })) || [],
      }
    },
    deviceInfoGroups() {
      if (!this.document.device) {
        return {
          title: 'Device Information',
          items: [],
        }
      }
      return [
        {
          title: 'Device Information',
          items: [
            { title: 'App Version', data: this.document.device.appVersion || "Unknown" },
            { title: 'Battery Level', data: `${this.document.device.batteryLevel}%` || "Unknown" },
            { title: 'Device Identifier', data: this.document.device.deviceIdentifer || "Unknown" },
            { title: 'Device Model', data: this.document.device.deviceModel || "Unknown" },
            { title: 'Is Low Power Enabled', data: this.document.device.isLowPowerEnabled || "Unknown" },
            { title: 'Locale', data: this.document.device.locale || "Unknown" },
            { title: 'Network Status', data: this.document.device.networkStatus || "Unknown" },
          ],
        },
      ];
    },
  },
  data() {
    return {
      boundingBoxes: [],
      isLoading: true,
      document: {},
      activeGroup: null,
      activeTitle: '',
      imageSize: {
        width: 0,
        height: 0,
      },
      activeItem: null,
    }
  },
  methods: {
    async fetchImageData(id) {
      console.log("Getting image data for", id)
      const docRef = doc(firestore, "detections", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.document = docSnap.data();
        this.boundingBoxes = this.document.detection.parts.map((box) => ({
          id: box.id,
          top: box.boundingBoxY,
          left: box.boundingBoxX,
          width: box.boundingBoxWidth,
          height: box.boundingBoxHeight,
        }))
        console.log("Detection document:", this.document);
        console.log("Image data:", this.boundingBoxes);
      } else {
        console.log("No such document!");
      }
      this.isLoading = false
    },
    viewDetails(group) {
      console.log(group)
      this.activeGroup = group;
      if (group == '') {
        this.activeTitle = '';
      } else {
        this.activeTitle = group.title;
      }
    },
    getImageSize() {
      const img = this.$refs.imageRef;
      this.imageSize.height = img.offsetHeight;
      this.imageSize.width = img.offsetWidth;
      console.log('Image Width:', this.imageSize.width);
      console.log('Image Height:', this.imageSize.height);
    },
    getBoxStyle(box) {
      const width = box.width * this.imageSize.width;
      const height = box.height * this.imageSize.height;
      const left = box.left * this.imageSize.width;
      const top = (1 - box.top) * this.imageSize.height - height; // Adjust for different origin
      return {
        width: `${width}px`,
        height: `${height}px`,
        left: `${left}px`,
        top: `${top}px`,
      };
    },
    exportData() {
      // Call the boundingBoxGroups function to get the bounding box data
      const boundingBoxGroups = this.document.detection.parts;
      console.log(boundingBoxGroups);
      
      // Format each detection as a string
      const formattedDetections = boundingBoxGroups.map(item => {
        return `'${item.observations[0].identifier}', ${item.boundingBoxX}, ${item.boundingBoxY}, ${item.boundingBoxWidth}, ${item.boundingBoxHeight}, ${item.observations[0].confidence}, ${item.colours[0].name}`;
      });

      // Join the formatted strings with newline characters
      const dataStr = formattedDetections.join('\n');

      // Create a Blob from the data
      const blob = new Blob([dataStr], { type: 'text/plain' });

      // Create a link element
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = this.documentId + '.txt';

      // Programmatically click the link to trigger the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(link.href);
    }
  },
  async created() {
    await this.fetchImageData(this.documentId)
  },
}
</script>

<style scoped>
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

td {
  max-width: 100%;
  white-space: nowrap;
}

.card-body {
  gap: 0;
}

.highlight {
  /* or any other highlight color */
}

.faded {
  opacity: 0.2;
  background-color: black;
}

.bounding-box {
  position: absolute;
  border: 2px solid red;
}
</style>